<template>
  <div>
    <BasicEditContainer
      ref="editor"
      :data-loaded="dataLoaded"
      v-if="dataLoaded"
      :title="t('title')"
      @cancel="cancel"
      :allow-delete="false"
      :object-type="$t('absence.data.object-type')"
      :object-name="item.user.display_name"
      :has-changes="hasChanges"
    >
      <template v-slot:default>
        <v-card elevation="0">
          <v-card-title>{{ t('timelog.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
              filled
              :label="t('timelog.employee')"
              v-model="item.user.display_name"
              :readonly="true"
              hide-details
            ></v-text-field>

            <timesheet-approval-edit-week-view
              class="mt-4"
              :start-date="item.date_from"
              v-model="item.timelogs"
            ></timesheet-approval-edit-week-view>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('journal.group-title') }}</v-card-title>
          <v-card-text class="py-0">
            <v-container>
              <v-row class="my-0">
                <v-col class="pa-0 pr-2 mr-1 day-width">
                  <div class="pl-2 py-2"><strong>{{ t('journal.day') }}</strong></div>
                </v-col>

                <v-col class="pa-0 px-2 mr-1 ellipsis-wrapper">
                  <div class="py-2"><strong>{{ t('journal.customer') }}</strong></div>
                </v-col>

                <v-col class=" pa-0 px-2 mr-1 ellipsis-wrapper">
                  <div class="py-2"><strong>{{ t('journal.project') }} / {{ t('journal.cost_center') }}</strong></div>
                </v-col>

                <v-col v-if="showFromTo" class="pa-0 mr-1 time-width">
                  <div class="pa-2"><strong>{{ t('journal.from') }}</strong></div>
                </v-col>

                <v-col v-if="showFromTo" class="pa-0 mr-1 time-width">
                  <div class="pa-2"><strong>{{ t('journal.to') }}</strong></div>
                </v-col>

                <v-col class="pa-0 pr-2 hours-width" >
                  <div class="py-2"><strong>{{ t('journal.hours') }}</strong></div>
                </v-col>

                <v-col v-if="theme === 'har'" class="pa-0 pr-2 ml-1 iconWidth">
                  <div class="py-2">{{ t('journal.holiday') }}</div>
                </v-col>

                <v-col v-if="theme === 'har'" class="pa-0 pr-2 ml-1 iconWidth">
                  <div class="pr-4 py-2">{{ t('journal.expenses') }}</div>
                </v-col>
              </v-row>

              <v-row v-for="timelog in timelogsOrdered" class="my-0 mb-1">
                <v-col class="pa-0 pr-2 mr-1 bg-field day-width">
                  <div class="pl-2 py-2">{{ formatDay(timelog.date) + ' ' + formatDate(timelog.date) }}</div>
                </v-col>

                <v-col class="pa-2 mr-1 ellipsis-wrapper bg-field">
                  <div v-if="timelog.customer" :title="timelog.customer.name" class="ellipsis ellipsis-title">{{ timelog.customer.name }}</div>
                </v-col>

                <v-col class="pa-2 mr-1 ellipsis-wrapper bg-field">
                  <div v-if="timelog.project" :title="timelog.project.name" class="ellipsis ellipsis-title">{{ timelog.project.name }}</div>
                  <div v-if="timelog.project" :title="timelog.project.cost_center" class="ellipsis preview-text">{{ timelog.project.cost_center }}</div>
                </v-col>

                <v-col v-if="showFromTo" class="pa-0 mr-1 time-width bg-field">
                  <div class="pa-2">{{ calcTimeFrom(timelog) }}</div>
                </v-col>

                <v-col v-if="showFromTo" class="pa-0 mr-1 time-width bg-field">
                  <div class="pa-2">{{ calcTimeTo(timelog) }}</div>
                </v-col>

                <v-col class="pa-0 pr-2 hours-width bg-field">
                  <div class="pa-0 py-2">{{ (timelog.duration_minutes / 60).toFixed(2) }}</div>
                </v-col>

                <v-col v-if="theme === 'har'" class="pa-0 pr-2 ml-1 bg-field iconWidth">
                  <div class="pr-4 py-2"><v-icon v-if="timelog.is_holiday == true" >mdi-party-popper</v-icon></div>
                </v-col>

                <v-col v-if="theme === 'har'" class="pa-0 pr-2 ml-1 bg-field iconWidth">
                  <div class="pr-4 py-2"><v-icon v-if="timelog.create_expense == true">mdi-hand-coin</v-icon></div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>

        <v-card v-if="!hasApprovalStatus" elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('comment.group-title') }}</v-card-title>
          <v-card-text>
            <v-textarea
              ref="commentTextArea"
              solo
              name="comment"
              v-model="approvalComment"
              :label="t('comment.hint')"
              :rules="rules.commentRules"
              hide-details="auto"
            ></v-textarea>
          </v-card-text>
        </v-card>
      </template>

      <template v-slot:meta>
        <v-card elevation="0">
          <v-card-title>{{ t('status.group-title') }}</v-card-title>
          <v-card-text>
            <div class="disabled-input">
              <label>{{ t('status.created-at') }}</label>
              <span>{{ formatDateTime(item.created_at) }}</span>
            </div>

            <div class="disabled-input">
              <label>{{ t('status.status') }}</label>
              <span>{{ $t('timesheet-approval.data.status.' + item.status) }}</span>
            </div>

            <div class="disabled-input" v-if="hasApprovalStatus">
              <label v-if="item.status === 'approved'">{{ t('status.approved-at') }}</label>
              <label v-if="item.status === 'rejected'">{{ t('status.rejected-at') }}</label>
              <span>{{ formatDateTime(item.approval_date) }}</span>
            </div>

            <div class="disabled-input" v-if="hasApprovalStatus">
              <label v-if="item.status === 'approved'">{{ t('status.approved-by') }}</label>
              <label v-if="item.status === 'rejected'">{{ t('status.rejected-by') }}</label>
              <span>{{ item.approved_by.display_name }}</span>
            </div>
          </v-card-text>
        </v-card>

        <v-card v-if="item.reviews && item.reviews.length > 0" elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('history.group-title') }}</v-card-title>
          <v-card-text>
            <div v-for="(review, index) in item.reviews" :key="index" clasS="review-item">
              <div class="d-flex justify-space-between">
                <small>{{ formatDateTime(review.date) }}</small>
                <small><b>{{ $t('timesheet-approval.data.status.' + review.decision.toLowerCase()) }}</b></small>
              </div>
              <v-textarea
                v-if="review.comment !== ''"
                class="pt-0 mt-0 review-comment"
                v-model="review.comment"
                rows="3"
                readonly
                hide-details
              ></v-textarea>
              <div class="text-right"><small>{{ review.reviewer_info.displayName }}</small></div>
            </div>
          </v-card-text>
        </v-card>
      </template>

      <template v-slot:action-buttons>
        <v-btn
          v-if="canEdit"
          elevation="0"
          class="icon-left"
          @click="reject()"
          :disabled="isProcessing"
          :loading="isProcessing"
        >
          <v-icon>mdi-check</v-icon>
          {{ t('reject') }}
        </v-btn>

        <v-btn
          v-if="canEdit"
          color="primary"
          elevation="0"
          class="icon-left"
          @click="approve()"
          :disabled="isProcessing"
          :loading="isProcessing"
        >
          <v-icon>mdi-check</v-icon>
          {{ t('approve') }}
        </v-btn>

        <v-btn
          v-if="canUnapprove"
          color="primary"
          elevation="0"
          class="icon-left"
          @click="unapprove()"
          :disabled="isProcessing"
          :loading="isProcessing"
        >
          <v-icon>mdi-lock-open-variant-outline</v-icon>
          {{ t('unapprove') }}
        </v-btn>
      </template>
    </BasicEditContainer>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import moment from 'moment/moment'
import store from 'store'
import loading from '@/components/layouts/Loading'
import toolbar from '@/components/layouts/Navigation'
import CancelButtonDialog from '@/components/layouts/CancelButtonDialog'
import TimesheetApprovalEditWeekView from '@/views/TimesheetApprovalEditWeekView'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'
import { hasPermission } from '@/services/PermissionService'

export default {
  name: 'TimesheetApprovalEdit',
  components: {
    BasicEditContainer,
    TimesheetApprovalEditWeekView,
    CancelButtonDialog,
    loading,
    toolbar
  },
  data() {
    return {
      itemId: null,
      item: null,
      dataLoaded: false,
      approvalComment: '',
      imageData: null,
      rules: {
        commentRules: [
          v => !!v || this.t('comment.error'),
        ],
      },
      record_mode: '',
      isProcessing: false
    }
  },
  async created() {
    this.itemId = this.$route.params.id
    await this.loadConfiguration()
    await this.loadData()
  },
  computed: {
    theme() {
      return store.get('theme')
    },
    canEdit() {
      return this.item && this.item.status === 'submitted'
    },
    canUnapprove() {
      return this.item && this.item.status === 'approved' && hasPermission('timelog_admin')
    },
    hasApprovalStatus() {
      return this.item && (this.item.status === 'approved' || this.item.status === 'rejected')
    },
    hasChanges() {
      return this.canEdit && this.approvalComment !== ''
    },
    timelogsOrdered() {
      return this.item.timelogs.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date)
      })
    },
    showFromTo() {
      return this.record_mode !== 'hours'
    }
  },
  methods: {
    t: function (key) {
      return this.$t('timesheet-approval.detail.' + key)
    },
    async loadConfiguration() {
      let response = await HTTP.get('timelog/settings')
      this.record_mode = response.data.record_mode
    },
    calcTimeFrom(item) {
      return moment(item.date).format("HH:mm")
    },
    calcTimeTo(item) {
      return moment(item.date).add(item.duration_minutes, 'minutes').format("HH:mm")
    },
    async loadData() {
      let response = await HTTP.get('timelog/timesheets/' + this.itemId)
      this.item = response.data.data
      if (this.item.reviews) {
        this.item.reviews = this.item.reviews.reverse()
      }
      this.dataLoaded = true
    },
    async approve() {
      await this.doAction('approve')
      this.$root.infoNotification.showMessage(this.t('approved-confirm-message'))
    },
    async unapprove() {
      await this.doAction('unapprove')
      this.$root.infoNotification.showMessage(this.t('unapproved-confirm-message'))
    },
    async reject() {
      if (!this.approvalComment) {
        this.$refs.editor.validate()
        this.$refs.commentTextArea.focus()
        return
      }
      await this.doAction('reject')
      this.$root.infoNotification.showMessage(this.t('reject-confirm-message'))
    },
    async doAction(action) {
      this.isProcessing = true
      const json = {
        timesheet_id: this.itemId,
        comment: this.approvalComment
      }
      await HTTP.post('timelog/' + action + '-timesheet', json)
      this.isProcessing = false
      this.gotoIndex()
    },
    cancel() {
      this.gotoIndex()
    },
    gotoIndex() {
      this.$router.push({name: 'TimesheetApproval'})
    },
  },
}
</script>

<style lang="scss" scoped>
.day-width {
  max-width: 120px;
}

.hours-width {
  max-width: 100px;
  text-align: right;
}

.time-width {
  max-width: 60px;
  text-align: center;
}

.icon-width {
  max-width: 85px;
  text-align: center;
}

.bg-field {
  background-color: #F5F5F5;
}

.ellipsis-wrapper {
  position: relative;
  min-width: 115px;

  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
}

.disabled-input {
  margin-bottom: 16px;
  font-size: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    display: block;
    font-weight: bold;

    &.small {
      font-size: 14px;
    }
  }
}

.review-item {

  & + .review-item {
    padding-top: 12px;
    margin-top: 12px;
    border-top: 1px solid #E0E0E0;
  }

  .review-comment ::v-deep textarea {
    padding: 8px 0;
  }
}
</style>

